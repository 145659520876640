import React from 'react';
import { createBrowserRouter } from 'react-router-dom';

import Root from './root';

import FatalError from './500';
import NoMatch from './404';
import Home from './Home';
import Qr from './qr';
import CreateDeal from './createDeal';

export default createBrowserRouter([
  {
    path: '',
    element: <Root />,
    errorElement: <FatalError />,

    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/create-deal/:address',
        element: <CreateDeal />,
      },
      {
        path: '/deal/:id',
        element: <Qr />,
      },
    ],
  },
  {
    path: '*',
    element: <NoMatch />,
  },
]);
