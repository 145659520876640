import { useState } from 'react';
import ModalUnderMaintenance from '../components/ModalUnderMaintenance';

export const useMaintance = () => {
  const { REACT_APP_MAINTANCE_TECH_PASS, REACT_APP_UNDER_MAINTANCE } =
    process.env;

  const storagePassKey = 'maintenance-password';
  const currentPass = localStorage.getItem(storagePassKey);

  const isMaintance = REACT_APP_UNDER_MAINTANCE === 'true';
  const isPassesMatch = currentPass === REACT_APP_MAINTANCE_TECH_PASS;

  const initialState = isMaintance && !isPassesMatch;

  const [isUnder, setIsUnder] = useState(initialState);

  const handleClose = () => {
    setIsUnder(false);
  };

  return [
    <ModalUnderMaintenance
      onClose={handleClose}
      storagePassKey={storagePassKey}
    />,
    isUnder,
    setIsUnder,
  ];
};
