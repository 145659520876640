import { Typography, Box, Container } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import CommonHeader from '../components/CommonHeader';

interface IBaseLayoutProps extends React.PropsWithChildren {}
const BaseLayout: React.FunctionComponent<IBaseLayoutProps> = ({
  children,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        minWidth: '100vw',
        minHeight: '100vh',
        bgcolor: 'converter.light',
      }}
    >
      <CommonHeader />
      <Container
        maxWidth="sm"
        sx={{ pl: '16px', pr: '16px', pt: 2 }}
        disableGutters
      >
        <Typography component="h1" variant="body2">
          {t('No such page exists.')}
        </Typography>
      </Container>
    </Box>
  );
};

export default BaseLayout;
