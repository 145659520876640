import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import TaskAltOutlinedIcon from '@mui/icons-material/TaskAltOutlined';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClose(): void;
  storagePassKey: string;
};

const ModalUnderMaintenance = (props: Props) => {
  const { REACT_APP_MAINTANCE_TECH_PASS: needPass } = process.env;

  const [pass, setPass] = useState('');
  const [error, setError] = useState(false);
  const [color, setColor] = useState<'primary' | 'success'>('primary');
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (isLoading) {
      return;
    }

    setError(false);
    setPass(e.currentTarget.value);
  };

  const handleClose = () => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);

      setError(needPass !== pass);

      if (needPass === pass) {
        setColor('success');
        setTimeout(() => {
          localStorage.setItem(props.storagePassKey, pass);
          props.onClose();
        }, 300);
      }
    }, 800);
  };

  return (
    <Dialog open={true} onClose={handleClose} fullWidth={true}>
      <DialogTitle>{t('Site in not available')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t('Site is under maintenance')}</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="password"
          label="preview password"
          type="password"
          fullWidth
          variant="standard"
          value={pass}
          onChange={onChange}
          error={error}
          color={color}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} size="small" color={color}>
          {t('Submit')}{' '}
          {isLoading && (
            <CircularProgress sx={{ ml: 1, width: 2, height: 2 }} />
          )}
          {color === 'success' && (
            <TaskAltOutlinedIcon
              color={color}
              sx={{ ml: 1 }}
              fontSize="medium"
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ModalUnderMaintenance;
