import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { postFetcher } from '../lib/api';
import { validateAddress } from '../lib/validateAddress';

export const useBaseDealProps = () => {
  const { address = '', id = '' } = useParams();

  const isAddressValid = validateAddress(address);
  const isDealAddressValid = validateAddress(id);
  const isDealIdBad = !!id && !isDealAddressValid;

  return {
    address,
    id,
    isDealIdBad,
    isAddressValid,
    isDealAddressValid,
  };
};

export const useDealCreate = () => {
  const { address, isDealIdBad, isAddressValid, isDealAddressValid } =
    useBaseDealProps();

  const canFetch = isAddressValid && !isDealAddressValid && !isDealIdBad;

  const random = useRef(Date.now()); // hack to workaround the cache

  const { data, error } = useSWR(
    canFetch ? ['/create', { address, network: 'BSCRPC' }, random] : null,
    postFetcher,
  );

  return {
    data,
    isLoading: !error && !data && canFetch,
    isError: error || data?.error,
  };
};

const useDealStatus = () => {
  const { id, isDealAddressValid } = useBaseDealProps();

  const canFetch = isDealAddressValid;

  const { data, error } = useSWR(
    canFetch ? ['/status', { id }] : null,
    postFetcher,
    { refreshInterval: 4000 },
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error || data?.error,
  };
};

const useDealView = () => {
  const { id, isDealAddressValid } = useBaseDealProps();

  const canFetch = isDealAddressValid;

  const { data, error } = useSWR(
    canFetch ? ['/view', { id }] : null,
    postFetcher,
  );

  return {
    data,
    isLoading: !error && !data,
    isError: error || data?.error,
  };
};

export const useDeal = () => {
  const { isDealIdBad, isAddressValid } = useBaseDealProps();

  const dealStatus = useDealStatus();
  const view = useDealView();

  return {
    data: {
      address: view.data?.order?.address,
      dealAddress: view.data?.order?.generatedAddress,
      statusData: dealStatus.data,
      createdAt: view.data?.order?.createdAt,
      lifetime: view.data?.order?.lifetime || 0,
    },
    isDealIdBad,
    isAddressValid,
    isLoading: dealStatus.isLoading || view.isLoading,
    isError: dealStatus.isError || view.isError,
  };
};
