export const BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const postFetcher = (url: string, body: any) => fetch(`${BASE_URL}${url}`, {
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify({
    ...(body || {}), 
    
})
}).then(r => r.json())