import useSWR from 'swr';
import { postFetcher } from '../lib/api';

export const useTechBalance = () => {
  const { data, error } = useSWR('/tech-balance', postFetcher, {
    refreshInterval: 5000,
  });

  return {
    data,
    isLoading: !error && !data,
    isError: error || data?.error,
  };
};
