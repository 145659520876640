import { Tooltip, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Grid2 from './Grid2';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { useTranslation } from 'react-i18next';

import QRCode from 'react-qr-code';

type Props = {
  address: string;
};

const QrCodeAddress = ({ address }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [isCopied, setIsCopied] = useState<boolean>(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 1100);
    }
  }, [isCopied]);

  return (
    <>
      <Grid2>
        <QRCode
          size={232}
          style={{ height: 'auto', maxWidth: '100%' }}
          value={address}
          viewBox={`0 0 232 232`}
          bgColor={theme.palette.converter.light}
        />
      </Grid2>

      <Grid2
        container
        alignItems="center"
        flexWrap="nowrap"
        gap={1}
        sx={{ maxWidth: '100%', overflow: 'hidden' }}
      >
        <Grid2 sx={{ maxWidth: 'calc(100% - 24px - 8px)' }}>
          <Typography
            variant="subtitle1"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              wordBreak: 'break-all',
            }}
          >
            {address}
          </Typography>
        </Grid2>
        <Grid2 container alignItems="center">
          <CopyToClipboard text={address} onCopy={() => setIsCopied(true)}>
            <Tooltip title={t('Copied')} arrow placement="top" open={isCopied}>
              <CopyAllIcon
                sx={{
                  opacity: 0.54,
                  color: 'common.black',
                  ':hover': {
                    opacity: 1,
                  },
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </CopyToClipboard>
        </Grid2>
      </Grid2>
    </>
  );
};

export default QrCodeAddress;
