import { Typography, Box, Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTechBalance } from '../hooks/useTechBalance';

import LayoutTrasfer from '../layout/LayoutTrasfer';
import { normalizeAddress, validateAddress } from '../lib/validateAddress';

type Props = {};

const Home = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [address, setAddress] = useState<string>('');
  const [addressIsValid, setAddressIsValid] = useState<boolean>(false);
  const techBalance = useTechBalance();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (e.currentTarget.checkValidity()) {
      navigate(`/create-deal/${normalizeAddress(address)}`);
    }
  };

  useEffect(() => {
    setAddressIsValid(validateAddress(address)); // check for correct address
  }, [address]);

  return (
    <LayoutTrasfer>
      <Box component="form" onSubmit={onSubmit} marginBottom={3}>
        <TextField
          fullWidth
          id="outlined-basic"
          label={t('Your BSC address')}
          variant="outlined"
          margin="normal"
          sx={{ mb: 3 }}
          required
          value={address}
          onChange={(e) => setAddress(e.currentTarget.value)}
        />

        <>
          {techBalance.data ? (
            <Typography variant="body1" marginBottom={2}>
              {t('Max withdrawal sum is')}{' '}
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 'bold', display: 'inline' }}
              >
                {techBalance.data.balance} OTON
              </Typography>
            </Typography>
          ) : (
            <Typography
              variant="subtitle2"
              marginBottom={2}
              sx={{
                width: '50%',
                background: 'linear-gradient(to right, #d1cecb, #f4f2f0)',
                color: 'translarent',
                borderRadius: 1,
                userSelect: 'none',
                height: 22,
              }}
            ></Typography>
          )}
        </>

        <Button
          type="submit"
          color="converter"
          variant="outlined"
          size="large"
          disabled={!addressIsValid}
        >
          {t('Withdrawal')}
        </Button>
      </Box>
    </LayoutTrasfer>
  );
};

export default Home;
