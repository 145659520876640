import React, { Suspense } from 'react';
import { createTheme } from '@mui/material/styles';
import { Box, CircularProgress, ThemeProvider } from '@mui/material';

export const themeOptions = createTheme({
  palette: {
    converter: {
      dark: '#182F37',
      light: '#F0EDEA',
      main: '#182F37',
      contrastText: '#F0EDEA',
    },
    text: {
      primary: '#182F37',
    },
  },
});

const App: React.FC<React.PropsWithChildren> = ({ children }) => {
  const globalLoader = (
    <Box
      sx={{
        minWidth: '100vw',
        minHeight: '100vh',
        bgcolor: 'converter.light',
        paddingBottom: 4,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size="80px" />
    </Box>
  );

  return (
    <ThemeProvider theme={themeOptions}>
      <Box sx={{ color: 'converter.dark' }}>
        <Suspense fallback={globalLoader}>{children}</Suspense>
      </Box>
    </ThemeProvider>
  );
};

export default App;
