import {
  List,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from '@mui/material';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FULFILLED,
  INIT,
  PENDING,
  StatusType,
} from '../constants/fetchStatuses';
import LanguageIcon from '@mui/icons-material/Language';
import Grid2 from './Grid2';
import { nanoid } from 'nanoid';

type Props = {};

const saveSupportedLngs = (lngs: string[]) => {
  localStorage.setItem('i18n-supported-lngs', JSON.stringify(lngs));
};
const getSavedSupportedLngs = (): string[] | undefined => {
  const data = localStorage.getItem('i18n-supported-lngs');

  if (!data) {
    return undefined;
  }

  try {
    const result = JSON.parse(data);

    if (!Array.isArray(result)) {
      return undefined;
    }

    return result;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

const LanguageSwitcher = (props: Props) => {
  const { i18n } = useTranslation();
  const [isWait, setIsWait] = useState(false);
  const [listState, setListState] = useState<StatusType>(INIT);
  const [supportedLngs, setSupportedLngs] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const popupId = useRef(nanoid());
  const anchorEl = useRef<HTMLDivElement>(null);

  const changeLang = async (lang: string) => {
    if (isWait) {
      return;
    }
    setIsWait(true);
    setIsOpen(false);
    await i18n.changeLanguage(lang);
    setIsWait(false);
  };

  const fetchLangList = useCallback(async () => {
    if (listState === PENDING || listState === FULFILLED) {
      return;
    }

    let result: string[];

    const cacheLangs = getSavedSupportedLngs();

    if (cacheLangs) {
      result = cacheLangs;
    } else {
      setListState(PENDING);
      const data =
        await i18n.services.backendConnector.backend.backends[1].getLanguages();

      result = Object.keys(data || {});

      saveSupportedLngs(result);
    }

    setSupportedLngs(result);

    setListState(FULFILLED);
  }, [i18n.services.backendConnector.backend.backends, listState]);

  useEffect(() => {
    fetchLangList();
  }, [fetchLangList]);

  return (
    <>
      <Grid2 container justifyContent="flex-end">
        <Grid2
          container
          sm="auto"
          alignItems="center"
          aria-describedby={popupId.current}
          ref={anchorEl}
          onClick={() => setIsOpen(true)}
          sx={{
            cursor: 'pointer',
            userSelect: 'none',
          }}
        >
          <LanguageIcon sx={{ mr: 0.7 }} fontSize="inherit" />

          <Typography
            sx={{
              textTransform: 'uppercase',
              fontWeight: '700',
              fontSize: '12px',
              lineHeight: '14px',
            }}
          >
            {i18n.language}
          </Typography>
        </Grid2>
      </Grid2>
      <Popover
        id={popupId.current}
        open={isOpen}
        anchorEl={anchorEl.current}
        onClose={() => setIsOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List sx={{ minWidth: '76px' }}>
          {supportedLngs

            .filter(
              (lang) =>
                !i18n.language.toLowerCase().includes(lang.toLowerCase()),
            )
            .map((lang) => (
              <ListItemButton key={lang} onClick={() => changeLang(lang)}>
                <ListItemText primary={lang} />
              </ListItemButton>
            ))}
        </List>
      </Popover>
    </>
  );
};

export default LanguageSwitcher;
