import { Container, Box } from '@mui/material';
import * as React from 'react';
import LanguageSwitcher from './LanguageSwitcher';
import Grid2 from './Grid2';
import { ReactComponent as Logo } from './../assets/Logo.svg';
import { Link } from 'react-router-dom';

interface ICommonHeaderProps extends React.PropsWithChildren {}

const CommonHeader: React.FunctionComponent<ICommonHeaderProps> = ({
  children,
}) => {
  return (
    <header>
      <Container maxWidth="sm" disableGutters sx={{ pl: 2, pr: 2 }}>
        <Box sx={{ pt: '12px', pb: '11px' }}>
          <Grid2 container alignItems="center">
            <Grid2 xs>
              <Link to="/">
                <Logo />
              </Link>
            </Grid2>
            <Grid2 xs justifyContent="flex-end">
              <LanguageSwitcher />
            </Grid2>
          </Grid2>
        </Box>
      </Container>
    </header>
  );
};

export default CommonHeader;
