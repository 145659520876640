import i18next from 'i18next';
import ChainedBackend from 'i18next-chained-backend';
import LocizeBackend from 'i18next-locize-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const isDev = process.env.NODE_ENV === 'development';

i18next
  .use(ChainedBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      backends: [LocalStorageBackend, LocizeBackend],
      backendOptions: [
        {
          expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
        },
        {
          private: false,
          projectId: '490a17ec-a3c7-4b92-8cf4-3fdeaf736aac',
          apiKey: process.env.REACT_APP_I18N_API_KEY,
          version: 'latest',
        },
      ],
    },

    saveMissing: isDev,
    appendNamespaceToMissingKey: isDev,
    debug: isDev,
    saveMissingTo: 'all',

    defaultNS: 'converter',
    ns: ['converter'],
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
