import { Box, Typography } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { Trans, useTranslation } from 'react-i18next';

interface Props extends PropsWithChildren {}

const LayoutTrasfer: React.FC<Props> = (props) => {
  const { t } = useTranslation();

  // default value for fallback
  const fromCurrency =
    process.env.REACT_APP_CONTENT_FROM_CURRENCY || 'Flight OTON';
  const toCurrency = process.env.REACT_APP_CONTENT_TO_CURRENCY || 'Flight BSC';

  return (
    <Box>
      <Typography component="h1" variant="h5" marginBottom={3}>
        <Trans
          i18nKey="converterAction" // optional -> fallbacks to defaults if not provided
          defaults="From <strong>{{fromCurrency}}</strong> to <strong>{{toCurrency}}</strong>" // optional defaultValue
          values={{ fromCurrency, toCurrency }}
          components={{ strong: <strong /> }}
        />
      </Typography>
      <Typography variant="subtitle2">
        {t('Enter BSC address to which you want to withdraw the tokens')}
      </Typography>

      {props.children}
    </Box>
  );
};

export default LayoutTrasfer;
