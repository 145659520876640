import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  TextField,
  Tooltip,
  Typography,
  Link as MuiLink,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import LayoutTrasfer from '../layout/LayoutTrasfer';
import QrCodeAddress from '../components/QrCodeAddress';
import Grid2 from '../components/Grid2';
import { Link, Navigate } from 'react-router-dom';

import { useDeal } from '../hooks/useDeal';
import Countdown from 'react-countdown';
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { useTechBalance } from '../hooks/useTechBalance';

type Props = {};

const withdrawalCommission = process.env.REACT_APP_WITHDRAWAL_COMISSION;
const mainCurrency = process.env.REACT_APP_MAIN_CURRENCY || 'OTON';
const minimalWithdrawal: string =
  process.env.REACT_APP_MINIMAL_WITHDRAWAL || '';

const Qr = (props: Props) => {
  const { t } = useTranslation();

  const [isCopied, setIsCopied] = useState<boolean>(false);
  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 1100);
    }
  }, [isCopied]);

  const {
    data: { address, dealAddress, statusData, createdAt, lifetime },
    isDealIdBad,
    isLoading,
    isError,
  } = useDeal();
  const techBalance = useTechBalance();

  const backButton = (
    <Button
      component={Link}
      to="/"
      replace={true}
      color="converter"
      variant="outlined"
      size="large"
    >
      {t('New withdraw')}
    </Button>
  );

  const isSuccess = statusData?.clientStatus === 5;
  const isBalanceLess = statusData?.clientStatus === 1;
  const isTimeout = statusData?.clientStatus === 6;
  const isLessThanMinSum = statusData?.clientStatus === 17;

  const isDealFinished = isSuccess || isTimeout;

  const dealWasCorrupted = isError || statusData?.clientStatus === 4;

  if (isDealIdBad) {
    return <Navigate to="/not-found" replace={true} />;
  }

  const permLink = (
    <>
      <Divider
        sx={{
          mb: 1,
        }}
      />

      <Typography
        sx={{
          wordBreak: 'break-word',
          fontWeight: 'bold',
        }}
      >
        {t('Постоянна ссылка на эту операцию: {{link}}', {
          link: '',
        })}
      </Typography>

      <Grid2
        container
        alignItems="center"
        flexWrap="nowrap"
        gap={1}
        sx={{ maxWidth: '100%', overflow: 'hidden' }}
      >
        <Grid2 sx={{ maxWidth: 'calc(100% - 24px - 8px)' }}>
          <Typography
            variant="subtitle1"
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {window.location.href}
          </Typography>
        </Grid2>
        <Grid2 container alignItems="center">
          <CopyToClipboard
            text={window.location.href}
            onCopy={() => setIsCopied(true)}
          >
            <Tooltip title={t('Copied')} arrow placement="top" open={isCopied}>
              <CopyAllIcon
                sx={{
                  opacity: 0.54,
                  color: 'common.black',
                  ':hover': {
                    opacity: 1,
                  },
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </CopyToClipboard>
        </Grid2>
      </Grid2>
    </>
  );

  const Field = ({ mb = 3 }: { mb?: number }) => (
    <TextField
      fullWidth
      id="outlined-basic"
      label={t('Your BSC address')}
      variant="outlined"
      margin="normal"
      sx={{ mb: mb }}
      required
      value={address}
      disabled={true}
    />
  );

  if (isBalanceLess) {
    return (
      <LayoutTrasfer>
        <Field mb={1} />

        <Alert severity="error" variant="filled" sx={{ mt: 2, mb: 2 }}>
          {t('Обмен не выполнен, обратитесь в службу поддержки')}{' '}
          <MuiLink
            variant="body1"
            color="primary.light"
            href="https://support.oton.org"
            target="_blank"
            rel="noreferrer"
          >
            support.oton
          </MuiLink>
        </Alert>
        {permLink}
      </LayoutTrasfer>
    );
  }

  if (isLessThanMinSum) {
    return (
      <LayoutTrasfer>
        <Field mb={1} />

        <Alert severity="error" variant="filled" sx={{ mt: 2, mb: 2 }}>
          {t('Отправленная сумма меньше минимальной')}
        </Alert>
        {backButton}
      </LayoutTrasfer>
    );
  }

  if (dealWasCorrupted) {
    return (
      <LayoutTrasfer>
        <Field mb={1} />

        <Alert severity="error" variant="filled" sx={{ mt: 2, mb: 2 }}>
          {t('Something went wrong')}
        </Alert>
        {backButton}
      </LayoutTrasfer>
    );
  }

  if (isLoading) {
    return (
      <LayoutTrasfer>
        <Field />

        <CircularProgress />
      </LayoutTrasfer>
    );
  }

  return (
    <LayoutTrasfer>
      <Field mb={1} />

      {!isDealFinished && (
        <Button
          component={Link}
          to="/"
          sx={{ mb: 3 }}
          color="converter"
          variant="outlined"
          size="large"
        >
          {t('Cancel withdrawal')}
        </Button>
      )}

      {!isDealFinished && (
        <>
          <Typography variant="subtitle2" marginBottom={0.2}>
            {t('Send the amount to this address.')}
          </Typography>
          {techBalance.data ? (
            <Typography variant="body1" marginBottom={2}>
              {t('Max withdrawal sum is')}{' '}
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 'bold', display: 'inline' }}
              >
                {techBalance.data.balance} OTON
              </Typography>
            </Typography>
          ) : (
            <Typography
              variant="subtitle2"
              marginBottom={2}
              sx={{
                width: '50%',
                background: 'linear-gradient(to right, #d1cecb, #f4f2f0)',
                color: 'translarent',
                borderRadius: 1,
                userSelect: 'none',
                height: 22,
              }}
            ></Typography>
          )}
        </>
      )}
      <Grid2
        flexDirection="column"
        alignItems="center"
        marginBottom={2}
        gap={2}
        container
        sx={{ bgcolor: 'converter.light', p: [2, 2, 3] }}
      >
        {!isSuccess && createdAt && !isTimeout && (
          <Grid2 textAlign="center">
            <Typography variant="caption">{t('Remaining time')}</Typography>
            <Typography variant="h6">
              <Countdown date={Date.parse(createdAt) + lifetime}>
                <span>00:00</span>
              </Countdown>
            </Typography>
          </Grid2>
        )}

        {!isDealFinished && <QrCodeAddress address={dealAddress} />}

        {isDealFinished && (
          <Box width="100%">
            {isTimeout && (
              <Alert severity="error" variant="filled" sx={{ mb: 3 }}>
                {t('Time is over. Create a new withdraw')}
              </Alert>
            )}
            {isSuccess && (
              <Alert severity="success" variant="filled" sx={{ mb: 3 }}>
                {t('It was successful!')}
              </Alert>
            )}
            {backButton}
          </Box>
        )}
      </Grid2>

      {+minimalWithdrawal > 0 && (
        <Alert severity="warning" sx={{ mb: 1 }}>
          {t('The minimum withdrawal amount is {{count}} OTON', {
            count: +minimalWithdrawal,
          })}
        </Alert>
      )}
      {withdrawalCommission && (
        <Alert severity="warning" sx={{ mb: 1 }}>
          <Trans
            i18nKey="Fee amount is {{fee}} {{currency}}"
            values={{ fee: withdrawalCommission, currency: mainCurrency }}
          />
        </Alert>
      )}

      <Alert severity="warning" sx={{ mb: 2 }}>
        {t('alert-info-oton-withdraw')}
      </Alert>

      {permLink}
    </LayoutTrasfer>
  );
};

export default Qr;
