import { Outlet } from 'react-router-dom';

import { useMaintance } from '../hooks/useMaintance';
import BaseLayout from '../layout/Base';

const Root = () => {
  const [Modal, isUnder] = useMaintance();

  if (isUnder) {
    return <>{Modal}</>;
  }

  return (
    <BaseLayout>
      <Outlet />
    </BaseLayout>
  );
};

export default Root;
