import { Alert, Button, CircularProgress, TextField } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import LayoutTrasfer from '../layout/LayoutTrasfer';

import { Link, Navigate } from 'react-router-dom';

import { useDealCreate, useBaseDealProps } from '../hooks/useDeal';
import { validateAddress } from '../lib/validateAddress';

type Props = {};

const CreateDeal = (props: Props) => {
  const { t } = useTranslation();

  const { address, isDealIdBad, isAddressValid } = useBaseDealProps();

  const { data, isLoading, isError } = useDealCreate();

  const backButton = (
    <Button
      component={Link}
      to="/"
      replace={true}
      color="converter"
      variant="outlined"
      size="large"
    >
      {t('New withdraw')}
    </Button>
  );

  if (validateAddress(data?.id || '')) {
    return <Navigate to={`/deal/${data.id}`} replace={true} />;
  }

  if (isDealIdBad) {
    return <Navigate to="/not-found" replace={true} />;
  }

  if (!isAddressValid) {
    return <Navigate to="/" replace={true} />;
  }

  const Field = ({ mb = 3 }: { mb?: number }) => (
    <TextField
      fullWidth
      id="outlined-basic"
      label={t('Your BSC address')}
      variant="outlined"
      margin="normal"
      sx={{ mb: mb }}
      required
      value={address}
      disabled={true}
    />
  );

  if (isError) {
    return (
      <LayoutTrasfer>
        <Field mb={1} />

        <Alert severity="error" variant="filled" sx={{ mt: 2, mb: 2 }}>
          {t('Something went wrong')}
        </Alert>
        {backButton}
      </LayoutTrasfer>
    );
  }

  return (
    <LayoutTrasfer>
      <Field />

      {isLoading && <CircularProgress />}
    </LayoutTrasfer>
  );
};

export default CreateDeal;
