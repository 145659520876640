import { Box, Container, Paper } from '@mui/material';
import * as React from 'react';

import CommonFooter from '../components/CommonFooter';
import CommonHeader from '../components/CommonHeader';

interface IBaseLayoutProps extends React.PropsWithChildren {
  hideHeader?: boolean;
  hideFooter?: boolean;
}

const BaseLayout: React.FunctionComponent<IBaseLayoutProps> = ({
  hideHeader,
  hideFooter,
  children,
}) => {
  return (
    <Box
      sx={{
        minWidth: '100vw',
        minHeight: '100vh',
        bgcolor: 'converter.light',
        paddingBottom: 4,
      }}
    >
      {!hideHeader && <CommonHeader />}

      <Container
        maxWidth="sm"
        component={Paper}
        elevation={0}
        disableGutters
        sx={{ pl: 2, pr: 2 }}
      >
        <Box sx={{ minHeight: '100px', pt: 2, pb: 2 }}>{children}</Box>
      </Container>

      {!hideFooter && <CommonFooter />}
    </Box>
  );
};

export default BaseLayout;
